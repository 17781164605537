import React, { useState, useEffect, useRef, useCallback } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { IoIosNotificationsOutline } from 'react-icons/io';
import {
  FaUser,
  FaLock,
  FaHistory,
  FaSignOutAlt,
  FaPaperPlane,
  FaClock,
  FaBars,
  FaArrowLeft,
} from 'react-icons/fa';
import CruiseDetails from '../CruiseDetails/CruiseDetails';
import CruiseBooking from '../Booking/Booking';
import PaymentPage from '../PaymentPage/PaymentPage';
import logo from '../../images/updated-logo.png';
import ships from '../../constants/ships';
import Footer from '../../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, onValue, push, update } from 'firebase/database';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../Firebaseconfig';
import './Dashboard.css';
import emailjs from 'emailjs-com';

const Dashboard = () => {
  const [currentTab, setCurrentTab] = useState('home');
  const [selectedShip, setSelectedShip] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [userFirstName, setUserFirstName] = useState('User');
  const [sortBy, setSortBy] = useState('lowToHigh');
  const notificationsRef = useRef(null);
  const navigate = useNavigate();
  const [tabHistory, setTabHistory] = useState(['home']);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const mobileMenuRef = useRef(null);
  const menuButtonRef = useRef(null);
  const dropdownMenuRef = useRef(null);
  const [userEmail, setUserEmail] = useState('');
  const [unreadCount, setUnreadCount] = useState(0);

  const cardContainerRef = useRef(null);
  const footerRef = useRef(null);

  // Scroll to a specific section
  const scrollToSection = (sectionRef) => {
    sectionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  useEffect(() => {
    const unreadNotifications = notifications.filter(
      (notification) => !notification.read
    );
    setUnreadCount(unreadNotifications.length);
  }, [notifications]);

  const totalPages = Math.ceil(ships.recommendations.length / itemsPerPage);

  const paginateCruises = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return sortCruises().slice(startIndex, endIndex);
  };

  const renderPagination = () => {
    const startIndex = (currentPage - 1) * itemsPerPage + 1; // Calculate start index
    const endIndex = Math.min(
      currentPage * itemsPerPage,
      ships.recommendations.length
    ); // Calculate end index

    return (
      <div>
        <div className='pagination'>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              className={`page-button ${
                currentPage === index + 1 ? 'active' : ''
              }`}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
        <div className='showing-text'>
          Showing {startIndex} - {endIndex} of {ships.recommendations.length}{' '}
          cruises
        </div>
      </div>
    );
  };

  const dashboardnavlist = [
    { title: 'My Profile', link: '/dashboard/profile', icon: <FaUser /> },
    { title: 'Change Password', link: '/dashboard/profile', icon: <FaLock /> },
    {
      title: 'Booking History',
      link: '/dashboard/profile',
      icon: <FaHistory />,
    },
    { title: 'Log Out', link: '/login', icon: <FaSignOutAlt /> },
  ];

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const db = getDatabase();
        const notificationsRef = ref(db, `notifications/${user.uid}`);
        onValue(notificationsRef, (snapshot) => {
          const data = snapshot.val() || {};
          console.log('Raw notifications data:', data);
          const notificationsArray = Object.entries(data).map(([id, value]) => {
            // If the notification is a plain string, use it as the message
            if (typeof value === 'string') {
              return { id, message: value, read: false };
            }
            // If the notification is an object, extract the message and read fields
            if (typeof value === 'string') {
              return { id, message: value, read: false }; // Use value directly as the message
            }

            // Default fallback for unexpected formats
            return { id, message: 'New notification', read: false };
          });

          setNotifications(notificationsArray);
        });
      }
    });
  }, []);

  // Fetch user data (e.g., name and notifications)
  useEffect(() => {
    const fetchUserData = () => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          const db = getDatabase();
          const userRef = ref(db, 'users/' + user.uid);
          onValue(userRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
              if (data.firstName) setUserFirstName(data.firstName); // Set user's first name
              if (data.email) setUserEmail(data.email); // Set user's email
            }
          });

          const notificationsDbRef = ref(db, 'notifications/' + user.uid);
          onValue(notificationsDbRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
              const notificationsArray = Object.entries(data).map(
                ([key, value]) => ({
                  id: key,
                  message:
                    typeof value.message === 'string'
                      ? value.message
                      : 'New notification',
                  read: value.read || false,
                })
              );
              setNotifications(notificationsArray);
            } else {
              addNotification('Welcome to our platform!');
            }
          });
        }
      });
    };

    fetchUserData();
  }, []);

  const sendEmail = () => {
    if (!userEmail) return;

    const formattedNotifications =
      notifications.length > 0
        ? notifications
            .map(
              (notification) =>
                `${notification.read ? '[Read]' : '[Unread]'} ${
                  notification.message
                }`
            )

            .join('\n')
        : 'No new notifications.';

    const templateParams = {
      to_name: userFirstName,
      user_email: userEmail,
      notifications: formattedNotifications,
    };

    emailjs
      .send(
        'service_twao09j',
        'template_2zq1v1u',
        templateParams,
        '0iGHvBCjFC3cqnR7W'
      )
      .then(
        (result) => console.log('Email sent successfully:', result.text),
        (error) => console.error('Failed to send email:', error.text)
      );
  };

  const markAllAsReadAndSendEmail = () => {
    // Update UI state
    const updatedNotifications = notifications.map((notification) => ({
      ...notification,
      read: true,
    }));
    setNotifications(updatedNotifications);

    // Batch update Firebase
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const db = getDatabase();
        const updates = {};
        notifications.forEach((notification) => {
          updates[`notifications/${user.uid}/${notification.id}/read`] = true;
        });
        update(ref(db), updates)
          .then(() => console.log('All notifications marked as read.'))
          .catch((error) =>
            console.error('Error updating notifications:', error)
          );
      }
    });

    // Optionally send an email
    sendEmail();
  };

  const addNotification = (message) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const db = getDatabase();
        const notificationsRef = ref(db, 'notifications/' + user.uid);
        const newNotification = {
          message: typeof message === 'string' ? message : 'New notification',
          read: false, // Default to unread
        };
        push(notificationsRef, newNotification)
          .then(() => console.log('Notification added successfully.'))
          .catch((error) => console.error('Error adding notification:', error));
      }
    });
  };

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };

  const sortCruises = () => {
    const sortedCruises = [...ships.recommendations].sort((a, b) => {
      const priceA = parseFloat(a.price.replace(/[^0-9.-]+/g, ''));
      const priceB = parseFloat(b.price.replace(/[^0-9.-]+/g, ''));
      return sortBy === 'lowToHigh' ? priceA - priceB : priceB - priceA;
    });
    return sortedCruises;
  };

  const toggleDropdown = () => {
    setDropdownOpen((prev) => {
      if (!prev) setNotificationsOpen(false); // Close notifications if opening dropdown
      return !prev;
    });
  };

  const toggleNotifications = () => {
    setNotificationsOpen((prev) => {
      if (!prev) setDropdownOpen(false); // Close dropdown if opening notifications
      return !prev;
    });
  };

  const handleTabChange = (newTab) => {
    setTabHistory((prevHistory) => [...prevHistory, newTab]);
    setCurrentTab(newTab);
  };

  const handleBack = () => {
    setTabHistory((prevHistory) => {
      if (prevHistory.length > 1) {
        const newHistory = prevHistory.slice(0, -1); // Remove the last tab
        setCurrentTab(newHistory[newHistory.length - 1]); // Set the previous tab as current
        return newHistory;
      }
      return prevHistory; // If there's no previous tab, keep history unchanged
    });
  };

  const markAllAsRead = () => {
    const updatedNotifications = notifications.map((notification) => ({
      ...notification,
      read: true,
    }));
    setNotifications(updatedNotifications);

    // Update Firebase
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const db = getDatabase();
        const updates = {};
        updatedNotifications.forEach((notification) => {
          updates[`notifications/${user.uid}/${notification.id}/read`] = true;
        });
        update(ref(db), updates)
          .then(() => console.log('Notifications updated.'))
          .catch((error) =>
            console.error('Error updating notifications:', error)
          );
      }
    });
  };

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen((prev) => !prev);
  };

  const markAsRead = (id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.id === id ? { ...notification, read: true } : notification
      )
    );

    onAuthStateChanged(auth, (user) => {
      if (user) {
        const db = getDatabase();
        const notificationRef = ref(db, `notifications/${user.uid}/${id}`);
        update(notificationRef, { read: true })
          .then(() => console.log('Notification marked as read.'))
          .catch((error) =>
            console.error('Error updating notification:', error)
          );
      }
    });
  };

  // Close menu when clicking outside
  const handleClickOutside = useCallback((event) => {
    // Close notifications modal
    if (
      notificationsRef.current &&
      !notificationsRef.current.contains(event.target)
    ) {
      setNotificationsOpen(false);
    }

    // Close dropdown menu
    if (
      dropdownMenuRef.current &&
      !dropdownMenuRef.current.contains(event.target)
    ) {
      setDropdownOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const renderTabContent = () => {
    switch (currentTab) {
      case 'home':
        return (
          <section className='card-section'>
            <div className='top-sectionn'>
              <h2>{`${ships.recommendations.length} cruises found`}</h2>
              <div>
                <label htmlFor='sort' className='sort-label'>
                  Sort by:
                </label>
                <select
                  value={sortBy}
                  onChange={handleSortChange}
                  className='sort-dropdown'
                >
                  <option value='lowToHigh'>Prices from low to high </option>
                  <option value='highToLow'>Prices from high to low</option>
                </select>
              </div>
            </div>
            <div className='card-container'>
              {paginateCruises().map((ship, index) => (
                <div
                  key={index}
                  className='card'
                  onClick={() => {
                    setSelectedShip(ship);
                    setCurrentTab('cruiseDetails');
                  }}
                >
                  <img
                    src={ship.image}
                    alt={ship.shipName}
                    className='card-image'
                  />
                  <div className='card-content'>
                    <h3>{ship.shipName}</h3>
                    <p>
                      <span className='icon-wrapper'>
                        <FaClock className='icon' />
                      </span>{' '}
                      {ship.heading}
                    </p>
                    <p>
                      <span className='icon-wrapper'>
                        <FaPaperPlane className='icon' />
                      </span>
                      {ship.description}
                    </p>
                    <div className='card-footer'>
                      <span>
                        From <strong>{ship.price}</strong>
                      </span>
                      <button
                        onClick={() => {
                          setSelectedShip(ship);
                          setCurrentTab('cruiseDetails');
                        }}
                        className='info-button'
                      >
                        More Info
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {renderPagination()}
          </section>
        );
      case 'cruiseDetails':
        return (
          <CruiseDetails
            selectedShip={selectedShip}
            onBack={() => setCurrentTab('home')}
            setCurrentTab={handleTabChange}
          />
        );
      case 'booking':
        return (
          <CruiseBooking
            selectedShip={selectedShip}
            setSelectedShip={setSelectedShip} // Pass setSelectedShip here
            setCurrentTab={handleTabChange}
            onBack={handleBack}
          />
        );

      case 'payment':
        return (
          <PaymentPage
            selectedShip={selectedShip}
            totalPrice={selectedShip?.totalPrice}
            onBack={handleBack}
          />
        );

      default:
        return <div>Welcome to the dashboard!</div>;
    }
  };

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        navigate('/login', { replace: true }); // Navigates and replaces history
      })
      .catch((error) => console.error('Logout failed', error));
  };

  return (
    <div className='dashboard'>
      <header className='dashboard-header'>
        <div className='logo'>
          <img src={logo} alt='Logo' />
        </div>

        <div className='desktop-dashboard-nav'>
          <nav className='dashboard-nav'>
            <a href='/' onClick={() => setCurrentTab('home')}>
              Home
            </a>
            <a
              href='#cruise'
              onClick={(e) => {
                e.preventDefault(); // Prevent default anchor behavior
                setCurrentPage(1); // Reset to first page
                setCurrentTab('home'); // Ensure it's on the home tab
              }}
            >
              Cruise
            </a>

            <a
              href='#footer'
              onClick={(e) => {
                e.preventDefault(); // Prevent default anchor behavior
                scrollToSection(footerRef);
              }}
            >
              Contact
            </a>
          </nav>

          <div className='user-menu'>
            <span className='notification-icon' onClick={toggleNotifications}>
              <IoIosNotificationsOutline />
              {notifications.length > 0 && (
                <span className='notification-count'>{unreadCount}</span>
              )}
            </span>
            {notificationsOpen && (
              <div ref={notificationsRef} className='notifications-modal'>
                <div className='notifications-header'>
                  <h3>Notifications</h3>
                  <button onClick={markAllAsReadAndSendEmail}>
                    Mark all as read
                  </button>
                </div>
                {notifications.length > 0 ? (
                  <ul className='notifications-list'>
                    {notifications.map((notification) => (
                      <li
                        key={notification.id}
                        className={`notification-item ${
                          notification.read ? 'read' : 'unread'
                        }`}
                        onClick={() => markAsRead(notification.id)}
                      >
                        {notification.message}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No new notifications</p>
                )}
              </div>
            )}

            <div className='user-div'>
              <span className='user-icon' onClick={toggleDropdown}>
                <FaUser />
              </span>
              <span className='username' onClick={toggleDropdown}>
                Hi, {userFirstName}
              </span>
              <button className='dropdown-button' onClick={toggleDropdown}>
                <RiArrowDropDownLine />
              </button>
            </div>

            {dropdownOpen && (
              <ul className='dropdown-menu'>
                {dashboardnavlist.map((item, index) => (
                  <li key={index}>
                    {item.title === 'Log Out' ? (
                      <button onClick={handleLogout} className='logout-button'>
                        {item.icon} {item.title}
                      </button>
                    ) : (
                      <a
                        href={item.link}
                        onClick={() => console.log(item.title)}
                      >
                        {item.icon} {item.title}
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        {/* Menu Button for Mobile */}
        <button
          className='mobile-menu-button'
          ref={menuButtonRef}
          onClick={toggleMobileMenu}
        >
          <FaBars />
        </button>
        {/* Mobile Menu Modal */}
        {mobileMenuOpen && (
          <div className='mobile-menu' ref={mobileMenuRef}>
            <button className='bacck-button' onClick={toggleMobileMenu}>
              <FaArrowLeft /> Back
            </button>

            <nav className='dashboard-nav'>
              <a href='/' onClick={() => setCurrentTab('home')}>
                Home
              </a>
              <a
                href='#cruise'
                onClick={(e) => {
                  e.preventDefault(); // Prevent default anchor behavior
                  scrollToSection(cardContainerRef);
                }}
              >
                Cruise
              </a>
              <a
                href='#footer'
                onClick={(e) => {
                  e.preventDefault(); // Prevent default anchor behavior
                  scrollToSection(footerRef);
                }}
              >
                Contact
              </a>
            </nav>

            <div className='user-menu'>
              <span className='notification-icon' onClick={toggleNotifications}>
                <IoIosNotificationsOutline />
                {notifications.length > 0 && (
                  <span className='notification-count'>
                    {notifications.length}
                  </span>
                )}
              </span>
              {notificationsOpen && (
                <div ref={notificationsRef} className='notifications-modal'>
                  <div className='notifications-header'>
                    <h3>Notifications</h3>
                    <button onClick={markAllAsRead}>Mark all as read</button>
                  </div>
                  {notifications.length > 0 ? (
                    <ul className='notifications-list'>
                      {notifications.map((notification) => (
                        <li
                          key={notification.id}
                          className={`notification-item ${
                            notification.read ? 'read' : 'unread'
                          }`}
                          onClick={() => markAsRead(notification.id)}
                        >
                          {notification.message}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No new notifications</p>
                  )}
                </div>
              )}

              <div className='user-div'>
                <span className='user-icon' onClick={toggleDropdown}>
                  <FaUser />
                </span>
                <span className='username' onClick={toggleDropdown}>
                  Hi, {userFirstName}
                </span>
                <button className='dropdown-button' onClick={toggleDropdown}>
                  <RiArrowDropDownLine />
                </button>
              </div>

              {dropdownOpen && (
                <ul className='dropdown-menu'>
                  {dashboardnavlist.map((item, index) => (
                    <li key={index}>
                      {item.title === 'Log Out' ? (
                        <button
                          onClick={handleLogout}
                          className='logout-button'
                        >
                          {item.icon} {item.title}
                        </button>
                      ) : (
                        <a
                          href={item.link}
                          onClick={() => console.log(item.title)}
                        >
                          {item.icon} {item.title}
                        </a>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        )}
      </header>
      <main className='dashboard-main'>{renderTabContent()}</main>
      <Footer />
    </div>
  );
};

export default Dashboard;
