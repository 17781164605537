import React from "react";
import { useNavigate } from "react-router-dom";
import "./PopupModal.css";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../Firebaseconfig";

const PopupModal = ({ ship, onClose }) => {
  const navigate = useNavigate();

  const handleInfoButtonClick = (ship) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate("/dashboard");
      } else {
        navigate("/login");
      }
    });
  };

  if (!ship) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modall-content" onClick={(e) => e.stopPropagation()}>
        <button className="closse-button" onClick={onClose}>
          &times;
        </button>
        <p>Book now and enjoy up to $500 OFF your cruise!</p>
        <img src={ship.image} alt={ship.shipName} className="modal-image" />
        <h3>{ship.shipName}</h3>
        <p className="ship-head">{ship.heading}</p>
        <p>{ship.description}</p>
        <p class="strikethrough">Originally $1250</p>

        <p>
          But now only <strong>{ship.price}</strong> per person!
        </p>

        <button
          className="book-now-button"
          onClick={() => handleInfoButtonClick(ship)}
        >
          Book Now
        </button>
      </div>
    </div>
  );
};

export default PopupModal;
